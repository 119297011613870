import {
    Abstract
} from './Abstract';

export class TMRole extends Abstract {
    constructor(session) {
        super('tm/Role', session);
        this.name = null;
    }
}
